import React from 'react';
import { useLocation } from 'react-router';
import cx from 'classnames';

import Button from '~/components/button';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import { urlFor } from '~/sanity/images';
import { analyticsBeacon, EventType } from '~/utils/analytics';
import { cookies } from '~/utils/cookie';

import styles from '~/components/static/promo-banner/promo-banner.module.scss';

export const BannerTemplate = {
  SINGLE: 'Single line', // Default styles
  DOUBLE: 'Double line',
  MINIMAL_SINGLE: 'Minimal - single line',
  MINIMAL_DOUBLE: 'Minimal - double line',
  MINIMAL_PATRIOTIC: 'Minimal - patriotic',
  PATRIOTIC: 'Patriotic',
  SITE_MAINTENANCE: 'Site maintenance',
};

export const BackgroundColor = {
  blue: '#4B6580',
  green: '#006156',
  orange: '#E02D3C',
  pink: '#C76693',
  tan: '#D08239',
  teal: '#24A599',
  white: '#FFF',
};

const PromoBanner = ({
  className,
  content,
  isLoadingContent,
  previewColor,
}) => {
  const { showFlashMessage } = useFlashMessage();
  const location = useLocation();

  if (!isLoadingContent && !content) {
    return null;
  }

  const {
    backgroundColor,
    bodyText,
    headlineText,
    image,
    promoCode,
    slug,
    template,
  } = !isLoadingContent && content;
  const displayColor = previewColor || BackgroundColor[backgroundColor];

  const handleClick = async () => {
    cookies.set('QUERY_PARAM_COUPON', promoCode, { expires: 14 });
    analyticsBeacon.emit(EventType.PROMO_BANNER_CODE_COPIED, {
      coupon: promoCode,
      pathname: location.pathname,
    });

    showFlashMessage(
      `Coupon code ${promoCode} will automatically be added to your cart`,
      'success',
      5000
    );
  };

  return (
    <div
      style={{ backgroundColor: displayColor }}
      className={cx(styles.promoBanner, className, {
        [styles.bannerOpen]: content,
        [styles.siteMaintWrapper]: template === BannerTemplate.SITE_MAINTENANCE,
        [styles.minPatrioticTemplateWrapper]:
          template === BannerTemplate.MINIMAL_PATRIOTIC,
        [styles.patrioticTemplateWrapper]:
          template === BannerTemplate.PATRIOTIC,
      })}
      id={`${slug}-promo-banner`}
    >
      <div
        className={cx(styles.promoBannerInner, {
          [styles.doubleTemplate]: template === BannerTemplate.DOUBLE,
          [styles.minSingleTemplate]:
            template === BannerTemplate.MINIMAL_SINGLE,
          [styles.minDoubleTemplate]:
            template === BannerTemplate.MINIMAL_DOUBLE,
          [styles.minPatrioticTemplate]:
            template === BannerTemplate.MINIMAL_PATRIOTIC,
          [styles.patrioticTemplate]: template === BannerTemplate.PATRIOTIC,
          [styles.siteMaintTemplate]:
            template === BannerTemplate.SITE_MAINTENANCE,
          [styles.whiteBackground]:
            displayColor === BackgroundColor.white &&
            (template !== BannerTemplate.MINIMAL_PATRIOTIC ||
              template !== BannerTemplate.PATRIOTIC),
        })}
      >
        <ImageBlock image={image} template={template} />

        <div className={styles.textBlock}>
          <h4>{headlineText}</h4>
          <p>{bodyText}</p>
        </div>

        {promoCode && template !== BannerTemplate.SITE_MAINTENANCE && (
          <div className={styles.buttonBlock}>
            <div className={styles.buttonBlockInner}>
              <p>Use code</p>
              <Button
                id="shop-sale-button"
                onClick={handleClick}
                className={styles.button}
                variant="yellow"
              >
                {promoCode}
              </Button>
              <p>
                <span>Use code </span>at checkout
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoBanner;

const ImageBlock = ({ image, template }) => {
  const mowerImg = {
    alt: 'Smoking lawn mower',
    src: '/images/smoking-mower.gif?v1',
  };

  const imageSrc = urlFor(image).width(300).fit('max').url();

  switch (template) {
    case BannerTemplate.SINGLE:
    case BannerTemplate.DOUBLE:
    case BannerTemplate.MINIMAL_SINGLE:
    case BannerTemplate.MINIMAL_DOUBLE:
      return image?.asset ? (
        <div className={styles.imageBlock}>
          <img src={imageSrc} width="80" height="80" alt={image.alt} />
        </div>
      ) : null;
    case BannerTemplate.MINIMAL_PATRIOTIC:
    case BannerTemplate.PATRIOTIC:
      return null;
    case BannerTemplate.SITE_MAINTENANCE:
      return (
        <div className={styles.imageBlock}>
          <img src={mowerImg.src} width="80" height="80" alt={mowerImg.alt} />
        </div>
      );
    default:
      return null;
  }
};
