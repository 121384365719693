import React from 'react';
import { Link } from 'react-router';

import FullWidthSection from '~/components/static/full-width-section';
import { ingredientsRoute } from '~/routes';
import LazyImg from '~/components/lazy-img';

import styles from '~/components/static/home/yellow-flags-section/yellow-flag-section.module.scss';

const YellowFlagSection = () => (
  <FullWidthSection
    backgroundColor="#38996C1A"
    id="homepage-yellow-flag-section"
  >
    <div className={styles.yellowFlagContent}>
      <LazyImg
        width="180"
        height="280"
        src="/images/home-page/caution-pesticide.png"
        alt="Caution sign that reads 'Caution: pesticide application. Keep off.'"
      />
      <div className={styles.infoBlock}>
        <h2>Those yellow flags are red flags</h2>
        <p>
          Traditional lawn care and pest control are wasteful, brute force
          approaches that lay down millions of pounds of harsh pesticides each
          year. We're on a mission to change that—and make sure you feel good
          about going barefoot in your backyard.
        </p>
        <Link to={ingredientsRoute()} data-heap-id="ingredients-link">
          See our ingredients
        </Link>
      </div>
      <div className={styles.notIncludedBlock}>
        <p>
          You <strong>won't</strong> find this junk in Sunday products:
        </p>
        <ul className={styles.notIncludedList}>
          <li>glyphosate</li>
          <li>imidacloprid</li>
          <li>bifenthrin</li>
          <li>trifluradin</li>
        </ul>
      </div>
    </div>
  </FullWidthSection>
);

export default YellowFlagSection;
