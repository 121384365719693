/**
 * PlanBundlePage tests
 */
// Mobile sticky footer CTA
export const MOBILE_STICKY_TEST = {
  NAME: 'full-mobile-sticky',
  VARIANT: 'full-sticky-flow',
};

// Allow for PDP modal to open in bundle shipment items
export const PDP_MODAL_TEST = {
  NAME: 'abtests-138-psp-product-details-modal-v3',
  SHOW_DETAIL_MODAL_VARIANT: 'show-product-details-modal',
};

// Show Smart Badges from quiz answers with bundles
export const SMART_BADGES_TEST = {
  NAME: 'psp-smart-badges',
  SHOW_BADGES_VARIANT: 'show-badges',
};

// Hardmark pricing test for lawn bundles
export const HARDMARK_TEST = {
  NAME: 'hardmark-bundle-pricing-196',
  HARDMARK_VARIANT: 'show-hardmark-price',
};

// Modal to update lawn size
export const LAWN_SIZE_TEST = {
  NAME: 'abtests-115-psp-lawn-size',
  LAWN_SIZE_VARIANT: 'update-lawn-size',
};

// Test alternate copy in shipment product tooltips
export const PRODUCT_TOOLTIP_TEST = {
  NAME: 'abtests-131-psp-product-tooltip',
  TOOLTIP_COPY_VARIANT: 'alt-product-tooltip',
};

export const NEED_HELP_FOOTER_TEST = {
  NAME: 'abtests-140-need-help-footer-psp',
  SHOW_NEED_HELP_VARIANT: 'show-need-help',
};

/**
 * Yard Funnel Tests
 */

export const YARD_FUNNEL_TEST = {
  NAME: 'abtests-171-yard-funnel',
  GARDEN_OG_PEST_VARIANT: 'garden-og-pest', // Lawn Funnel -> OG Pest Funnel -> Garden Funnel (if all options selected)
  GARDEN_PEST_IN_LAWN_VARIANT: 'garden-pest-in-lawn', // Lawn Funnel -> Pest-in-Lawn Funnel -> Garden Funnel (if all options selected)
};

/**
 * SmartLawnPlanPage - AddressForm
 */
// Tooltip for why we ask for user's address
export const WHY_TOOLTIP_TEST = {
  NAME: 'why-do-we-ask-tooltip',
  SHOW_TOOLTIP_VARIANT: 'show-tooltip',
};

/**
 * ProductDetailsPage
 */
// Chatbot help
export const PDP_CHAT_BOT_TEST = {
  NAME: 'pdp-chat-bot',
  SHOW_CHATBOT_VARIANT: 'show-chat-bot',
};

/**
 * CartPage
 */
// Hide mobile sticky CTA
export const HIDE_MOBILE_CTA_TEST = {
  NAME: 'abtests-105-mobile-cta-cart',
  HIDE_CTA_VARIANT: 'hide-mobile-cta',
};

export const SUNNY_AI_PSP_FAQ_TEST = {
  NAME: 'abtests-107-sunny-ai-psp-faq',
  SHOW_FAQ_VARIANT: 'show-faqs-modal',
  SHOW_CHATBOT_VARIANT: 'show-chat-bot',
};

export const SEED_FINDER_ZIP_CODE_TEST = {
  NAME: 'abtests-144-seed-finder-zip-code',
  ZIP_CODE_VARIANT: 'zip-code',
};

// Show product add-ons on cart page, skip FunnelProductsPage
export const CART_ADD_ONS_TEST = {
  NAME: 'abtests-160-funnel-cart-add-ons',
  SHOW_ADD_ONS_VARIANT: 'cart-add-ons',
};
